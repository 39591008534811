import React from "react";
import styles from "./index.module.scss";

const GrowYourApp = ({ title }) => {
  return (
    <section className={styles.root}>
      <div>
        <h2 className={styles.title}>
          {title || "Let’s grow your app revenue together"}
        </h2>
        <div className={styles.cards}>
          <a
            href="/blog/arpas-case-study"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.card}>
              <div className={styles.tag}>+21% revenue</div>
              <div className={styles.heading}>
                <div className={styles.icon}>
                  <img src={"/home/f3.svg"} alt="" width="100" height="80" />
                </div>
                <label>Lumos</label>
              </div>
              <p>
                The Lumos team optimized user acquisition campaigns on trials
                and analized the ARPAS metric
              </p>
            </div>
          </a>
          <a
            href="/blog/experiments-case-study"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.card}>
              <div className={styles.tag} aria-color="yellow">
                +61% CR
              </div>
              <div className={styles.heading}>
                <div className={styles.icon}>
                  <img src={"/home/f1.svg"} alt="" width="100" height="80" />
                </div>
                <label>AEZAKMI Group</label>
              </div>
              <p>
                AEZAKMI Group doubled app revenue and increase the conversion
                rate to subscription by 61% with A/B experiments
              </p>
            </div>
          </a>
          <a
            href="/blog/uplens-increased-app-revenue-using-apphud"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.card}>
              <div className={styles.tag} aria-color="green">
                9Х revenue
              </div>
              <div className={styles.heading}>
                <div className={styles.icon}>
                  <img src={"/home/f2.svg"} alt="" width="100" height="80" />
                </div>
                <label>Uplens</label>
              </div>
              <p>
                The Uplens team increased app revenue by 900% in 9 months with
                Apphud’s analytics and the push notification automation tool
              </p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default GrowYourApp;
