import styles from "./Tab.module.scss";
import React from "react";

const Tab = (props) => {
  const { prefix, suffix, title, children } = props;
  return (
    <div className={styles.root}>
      {prefix}
      <div className={styles.content}>
        <label className="subheader-medium h4">{title}</label>
        <p className="text">{children}</p>
      </div>
      {suffix}
    </div>
  );
};

export default Tab;
