import { useState, useEffect } from "react";

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Initial setting
      setWindowWidth(window.innerWidth);

      const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleWindowResize);

      // Clean up function
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  }, []); // Empty array ensures effect only runs on mount and unmount

  return windowWidth;
}

export default useWindowWidth;
