import React from "react";
import styles from "./index.module.scss";
import useWindowWidth from "hooks/useWindowWidth";
import cn from "classnames";

export const PromoBanner = ({ text }) => {
  return (
    <div className={text ? cn(styles.block, styles.block_alt) : styles.block}>
      {text ? (
        <div className={styles.label}>{text}</div>
      ) : (
        <>
          <div className={styles.label}>You’re one step away from the</div>
          <div className={styles.label}>new actionable insights</div>
          <div className={styles.label}>Are you in?</div>
        </>
      )}

      <div className={styles.actions}>
        <a href="/demo" target="_blank" rel="noopener noreferrer">
          <button className={styles.button}>Book a demo</button>
        </a>
        <a
          href="https://app.apphud.com/sign_up"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={`${styles.button} ${styles.link}`}>
            Start for free
          </button>
        </a>
      </div>
    </div>
  );
};

const Promo = () => {
  const width = useWindowWidth();
  return (
    <section className={styles.root}>
      <div className={cn(width > 768 ? "container" : "")}>
        <h2 className={styles.title}>Get app growth insights on our blog</h2>
        <div className={styles.posts}>
          <a
            href="/blog/best-performing-paywallls"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.post}>
              <div className={styles.poster}>
                <img
                  src="/home/blog1.webp"
                  alt=""
                  width="375"
                  height="214.84"
                />
              </div>
              <label>
                Best-performing paywall examples for subscription apps
              </label>
            </div>
          </a>
          <a
            href="/blog/apple-search-ads-guide-how-to-launch-and-analyze"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.post}>
              <div className={styles.poster}>
                <img
                  src="/home/blog2.webp"
                  width="375"
                  height="214.84"
                  alt=""
                />
              </div>
              <label>
                How to launch and analyze your Apple Search Ads campaigns
              </label>
            </div>
          </a>
          <a
            href="/blog/ab-test-ideas-for-subscription-apps"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.post}>
              <div className={styles.poster}>
                <img
                  src="/home/blog3.webp"
                  width="375"
                  height="214.84"
                  alt=""
                />
              </div>
              <label>A/B Test ideas for subscription apps</label>
            </div>
          </a>
        </div>
        <PromoBanner />
      </div>
    </section>
  );
};

export default Promo;
