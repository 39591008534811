import React from "react";
import styles from "./index.module.scss";

const InAppRevenue = () => {
  return (
    <section className={styles.root}>
      <div className="container">
        <h2 className={styles.title}>#1 in app revenue tracking accuracy</h2>
        <div className={styles.tags}>
          <span>
            99.9% data match with AppStore Connect and GooglePlay Console
          </span>
          <span>Flawless tax calculation for 176 countries</span>
          <span>User-level data</span>
        </div>
        <div className={styles.card}>
          <div className={styles.list}>
            <div className={styles.item}>
              <label>
                <img src={"/home/logo.svg"} alt="alt" width="24" height="24" />
                &nbsp; With Apphud
              </label>
              <div>
                <div className={styles.value}>up to 0,01%</div>
                <span>of data discrepancy</span>
              </div>
            </div>
            <div className={styles.item}>
              <label>In-House or Other tools</label>
              <div>
                <div className={styles.value}>up to 15%</div>
                <span>of data discrepancy</span>
              </div>
            </div>
          </div>
          <div className={styles.images}>
            <div className={styles.col}>
              <img src={"/home/l4.svg"} width="1056" height="214" alt="alt" />
            </div>
            <div className={styles.col}>
              <img src={"/home/l1.svg"} alt="alt" width="148" height="60" />
              <img src={"/home/l2.svg"} alt="alt" width="148" height="60" />
              <img src={"/home/l3.svg"} alt="alt" width="148" height="60" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InAppRevenue;
