import React from "react";
import styles from "./index.module.scss";

const ButtonArea = ({ children }) => {
  return (
    <section className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>{children}</div>
      </div>
    </section>
  );
};

export default ButtonArea;
