import React from "react";
import styles from "./styles.module.scss";

const Gallery = () => {
  return (
    <section className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <h2 className={styles.title}>What mobile experts tell about us</h2>
          <div className={styles.grid}>
            <div className={styles.col} aria-colspan={1}>
              <div className={styles.card}>
                <a
                  href="https://www.g2.com/products/apphud/reviews"
                  target="_blank"
                >
                  <img
                    width="281"
                    height="97"
                    src={"/home/gstars.svg"}
                    alt=""
                  />
                </a>
              </div>
              <div className={styles.card}>
                <a
                  href="https://www.capterra.com/p/236551/Apphud"
                  target="_blank"
                >
                  <img
                    width="271"
                    height="81"
                    src={"/home/capterra.svg"}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className={styles.col} aria-colspan={2}>
              <div className={styles.card}>
                <div className={styles.text}>
                  Speaking from experience I can tell you that Apphud is a
                  reliable and insightful subscription infrastructure with
                  precise app revenue tracking. Apphud takes a great deal of
                  necessary work on itself allowing us to zero in on product
                  development, monetization as well as user acquisition. Our
                  team has been doing business with Apphud for 2 years and fully
                  trusts them.
                </div>
                <div className={styles.user}>
                  <div className={styles.icon}>
                    <img
                      src={"/home/av1_1.webp"}
                      alt="alt"
                      width="55"
                      height="55"
                    />
                  </div>
                  <div className={styles.info}>
                    <b>Alexander Rabtsevich</b>
                    <br />
                    <span>Founder&CEO at PlantMe</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.col} aria-colspan={1}>
              <div className={styles.card}>
                <div className={styles.text}>
                  Apphud helps us to focus more on development and marketing
                  things and don’t waste time on in-house infrastructure support
                  headaches. We can be sure that Apphud provides precise event
                  tracking, allowing us to make the right decisions.
                </div>
                <div className={styles.user}>
                  <div className={styles.icon}>
                    <img
                      src={"/home/av1.webp"}
                      alt="alt"
                      width="55"
                      height="55"
                    />
                  </div>
                  <div className={styles.info}>
                    <b>Oleg Ivanov</b>
                    <br />
                    <span>CEO at Bright Mobile Apps</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.col} aria-colspan={1}>
              <div className={styles.card}>
                <div className={styles.text}>
                  Apphud has saved us a ton of time! Convenient charts allow
                  tracking all crucial app metrics with ease. Also, there is a
                  cool no-code solution to win-back canceled subscribers with
                  embedded surveys inside! Worth mentioning, that we love to use
                  a remarkable Apple Search Ads integration for installs
                  attribution, this helps us to measure the app campaign's
                  efficiency.
                </div>
                <div className={styles.user}>
                  <div className={styles.icon}>
                    <img
                      src={"/home/av5.webp"}
                      alt="alt"
                      width="55"
                      height="55"
                    />
                  </div>
                  <div className={styles.info}>
                    <b>Victor Sharov</b>
                    <br />
                    <span>CEO/Founder at My Water</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.col} aria-colspan={1}>
              <div className={styles.card}>
                <div className={styles.text}>
                  With Apphud, we are always up-to-date with monetization
                  metrics and have an informative in-app subscriptions funnel.
                  We can experiment with prices and paywalls without having to
                  make a release. Support is always in touch, helping to solve
                  our tricky requests. Thank you for a great product we can
                  trust!
                </div>
                <div className={styles.user}>
                  <div className={styles.icon}>
                    <img
                      src={"/home/av2.webp"}
                      alt="alt"
                      width="55"
                      height="55"
                    />
                  </div>
                  <div className={styles.info}>
                    <b>Alexandra Vorotnikova</b>
                    <br />
                    <span>PO at Movavi Clips</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.col} ${styles.inline}`} aria-colspan={1}>
              <div className={`${styles.card} ${styles.card_padding}`}>
                <img
                  src={"/homeNew/medal_02.svg"}
                  alt=""
                  height={116.5}
                  width={100.88}
                />
              </div>
              <div className={`${styles.card} ${styles.card_padding}`}>
                <img
                  src={"/homeNew/medal_01.svg"}
                  alt=""
                  height={116.5}
                  width={100.88}
                />
              </div>
              <div className={`${styles.card} ${styles.card_padding}`}>
                <img
                  src={"/homeNew/medal_03.svg"}
                  alt=""
                  height={116.5}
                  width={100.88}
                />
              </div>
              <div className={`${styles.card} ${styles.card_padding}`}>
                <img
                  src={"/homeNew/medal_04.svg"}
                  alt=""
                  height={116.5}
                  width={100.88}
                />
              </div>
            </div>
            <div className={styles.col} aria-colspan={2}>
              <div className={styles.card}>
                <div className={styles.text}>
                  Supporting reliable and secure infrastructure is a big hassle.
                  With Apphud, we got everything we needed just out-of-the-box!
                  Crucial points for us were infrastructure stability, data
                  accuracy, and close to 100% uptime. Another thing we love
                  about Apphud is that the team is always up-to-date with actual
                  Apple and Google updates and always hearing our feature
                  requests. Just amazing!
                </div>
                <div className={styles.user}>
                  <div className={styles.icon}>
                    <img
                      src={"/home/av4.webp"}
                      alt="alt"
                      width="55"
                      height="55"
                    />
                  </div>
                  <div className={styles.info}>
                    <b>Denis Sevastiyanov</b>
                    <br />
                    <span>CMO at Prequel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
