import React from "react";
import styles from "./styles.module.scss";
import Tabs from "../../UI/Tabs";

const TabOneSection = (props) => {
  const { title, description, children, type = "first", tabs = [] } = props;
  return (
    <section className={`${styles.root} ${styles[type]}`}>
      <div className="container">
        <div className={styles.heading}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
        </div>

        <Tabs nav={tabs}>{children}</Tabs>
      </div>
    </section>
  );
};

export default TabOneSection;
