import React from "react";
import styles from "./index.module.scss";
import Button from "../../UI/Button";
import Link from "next/link";
import Apps from "../Apps";

const Jumbotron = () => {
  return (
    <section className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            Skyrocket your app growth with a revenue data suite
          </h1>
          <p className={styles.description}>
            Apphud covers every aspect when it comes to in-app subscriptions
            from integration to analytics on iOS and Android
          </p>
          <div className={styles.buttons}>
            <a href="/demo" target="_blank" rel="noopener noreferrer">
              <Button color="blue" title="Book a demo" />
            </a>
            <Link href={"https://app.apphud.com/sign_up"}>
              <Button color="blue" transparent={true} title="Start for free" />
            </Link>
          </div>
        </div>
      </div>
      <Apps />
    </section>
  );
};

export default Jumbotron;
