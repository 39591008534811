import React from "react";
import styles from "./index.module.scss";
import Button from "components/UI/Button";
import Image from "next/image";
import useWindowWidth from "hooks/useWindowWidth";

const Predictions = () => {
  const width = useWindowWidth();
  return (
    <section className={styles.root}>
      <div className="container">
        <h2 className={styles.title}>
          Accurately predict customer lifetime value with innovative analytics
        </h2>
        <div className={styles.description}>
          LTV prediction tool is designed to provide you with invaluable
          insights into your customers' lifetime value, enabling you to make
          data-driven decisions and optimize your marketing strategies for
          maximum profitability
        </div>
      </div>
      <div className={styles.content}>
        <Image
          src={width > 640 ? "/homeNew/ltv.svg" : "/homeNew/ltv_mobile.svg"}
          width={width > 640 ? 762 : 320}
          height={width > 640 ? 560 : 235}
          alt="ltv"
        />
        <div className={styles.points}>
          <div className={styles.point}>
            <div className={styles.pointTitle}>Enhanced marketing strategy</div>
            <div className={styles.pointDescription}>
              Level up your marketing with LTV predictions to identify
              high-value customers, anticipate churn, and tailor campaigns
              accordingly
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.pointTitle}>Optimal resource allocation</div>
            <div className={styles.pointDescription}>
              Allocate your resources wisely by identifying which customer
              segments contribute the most to your revenue stream
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.pointTitle}>Increased revenue and ROI</div>
            <div className={styles.pointDescription}>
              Maximize your return on investment by focusing on customers with
              the highest LTV
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              onClick={() => window.open("mailto:support@apphud.com", "_blank")}
              title="Contact us"
            />
            <Button
              onClick={() =>
                window.open(
                  "https://docs.apphud.com/docs/predictions",
                  "_blank"
                )
              }
              transparent
              title="Learn more"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Predictions;
